<script>
  export let appUrl;
  export let appId;

  import ChatIframe from "./ChatIframe.svelte";
  import ChatBubbleIcon from "./ChatBubbleIcon.svelte";
  import NotificationBubble from "./NotificationBubble.svelte";
  import ChatMessagePreview from "./ChatMessagePreview.svelte";
  import CloseIcon from "./CloseIcon.svelte";
  import { onMount, onDestroy } from "svelte";

  let fullIframeVisible = false;
  let chatPreviewVisible = false;

  function toggleIframeVisibility() {
    fullIframeVisible = !fullIframeVisible;
    chatPreviewVisible = false;

    // Get rid of the proxy object stuff from MobX
    const serializedAppInfo = JSON.stringify(applicationInfo);
    sendAppInfoToIframe(JSON.parse(serializedAppInfo));
  }

  let applicationInfo = {};

  const isChippBuilder =
    window.location.href.includes("chipp.ai") ||
    window.location.href.includes("localhost");
  fetch(`https://api.chipp.ai/applications/${appId}`)
    .then((response) => response.json())
    .then((data) => {
      applicationInfo = window.CHIPP_APPLICATION_STATE || data;
      setTimeout(
        () => {
          chatPreviewVisible = true;
        },
        isChippBuilder ? 1000 : 3000
      );
    });

  function handleShowChatPreview() {
    chatPreviewVisible = true;
    fullIframeVisible = false;
  }
  function handleShowIframe() {
    fullIframeVisible = true;
    chatPreviewVisible = false;

    // Get rid of the proxy object stuff from MobX
    const serializedAppInfo = JSON.stringify(applicationInfo);
    sendAppInfoToIframe(JSON.parse(serializedAppInfo));
  }
  function handlePreviewMessageChanged(event) {
    applicationInfo.startingMessage = event.detail;
  }
  function sendAppInfoToIframe(appInfo) {
    const iframe = document.getElementById("chipp-chat-widget-full");
    if (!iframe) {
      console.error("Iframe not found");
      return;
    }

    // Construct the message with the updated application info
    const message = {
      type: "appInfoUpdated",
      application: appInfo,
    };

    // Dynamically determine the iframe's origin from its src attribute
    const targetOrigin = new URL(iframe.src).origin;

    // Check if the iframe is already loaded by checking if contentWindow is available
    if (iframe.contentWindow) {
      // Send the message to the iframe immediately
      iframe.contentWindow.postMessage(message, targetOrigin);
    }
  }
  function handleAppInfoUpdated(event) {
    applicationInfo = event.detail;

    // Get rid of the proxy object stuff from MobX
    const serializedAppInfo = JSON.stringify(applicationInfo);
    sendAppInfoToIframe(JSON.parse(serializedAppInfo));
  }

  onMount(() => {
    window.addEventListener(
      "chipp:widget:appInfoUpdated",
      handleAppInfoUpdated
    );
    window.addEventListener("chipp:widget:compact", handleShowChatPreview);
    window.addEventListener("chipp:widget:expanded", handleShowIframe);
    window.addEventListener(
      "chipp:widget:previewMessageChanged",
      handlePreviewMessageChanged
    );

    // Send the initial application info to the iframe
    const serializedAppInfo = JSON.stringify(applicationInfo);
    sendAppInfoToIframe(JSON.parse(serializedAppInfo));
  });

  onDestroy(() => {
    window.removeEventListener(
      "chipp:widget:appInfoUpdated",
      handleAppInfoUpdated
    );
    window.removeEventListener("chipp:widget:compact", handleShowChatPreview);
    window.removeEventListener("chipp:widget:expanded", handleShowIframe);
    window.removeEventListener(
      "chipp:widget:previewMessageChanged",
      handlePreviewMessageChanged
    );
  });
</script>

<div>
  <ChatMessagePreview
    {appId}
    visible={chatPreviewVisible}
    {applicationInfo}
    onClick={toggleIframeVisibility}
  />
  <ChatIframe
    {appUrl}
    visible={fullIframeVisible}
    onClick={toggleIframeVisibility}
  />
  <button id="chipp-chat-btn" on:click={toggleIframeVisibility}>
    {#if fullIframeVisible}
      <CloseIcon />
    {:else}
      <ChatBubbleIcon />
    {/if}
    {#if chatPreviewVisible}
      <NotificationBubble
        backgroundColor={applicationInfo.brandStyles
          ? applicationInfo.brandStyles.primaryColor || "#f9db00"
          : "#f9db00"}
      />
    {/if}
  </button>
</div>

<style>
  #chipp-chat-btn {
    cursor: pointer;
    position: fixed;
    z-index: 2147483646;
    bottom: 25px;
    right: 25px;
    border-radius: 50%;
    border: none;
    background: transparent;
    padding: 0;
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    box-shadow:
      0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);
  }
  #chipp-chat-btn:hover {
    background-color: #f8f8f8;
    transform: scale(1.1);
    box-shadow:
      0 10px 30px -3px rgba(0, 0, 0, 0.1),
      0 4px 12px -4px rgba(0, 0, 0, 0.1);
  }
  #chipp-chat-btn:active {
    background-color: #f0f0f0;
    transform: scale(0.95);
    box-shadow:
      0 5px 10px -2px rgba(0, 0, 0, 0.2),
      0 2px 4px -2px rgba(0, 0, 0, 0.2);
  }
</style>
