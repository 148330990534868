<div id="chipp-close-icon-container">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clipPath="url(#clip0_6107_8093)">
      <path
        d="M23.7072 0.576966C23.5196 0.389495 23.2653 0.28418 23.0002 0.28418C22.735 0.28418 22.4807 0.389495 22.2932 0.576966L12.0002 10.87L1.70715 0.576966C1.51963 0.389495 1.26532 0.28418 1.00015 0.28418C0.734988 0.28418 0.48068 0.389495 0.293153 0.576966C0.105682 0.764494 0.000366211 1.0188 0.000366211 1.28397C0.000366211 1.54913 0.105682 1.80344 0.293153 1.99097L10.5862 12.284L0.293153 22.577C0.105682 22.7645 0.000366211 23.0188 0.000366211 23.284C0.000366211 23.5491 0.105682 23.8034 0.293153 23.991C0.48068 24.1784 0.734988 24.2838 1.00015 24.2838C1.26532 24.2838 1.51963 24.1784 1.70715 23.991L12.0002 13.698L22.2932 23.991C22.4807 24.1784 22.735 24.2838 23.0002 24.2838C23.2653 24.2838 23.5196 24.1784 23.7072 23.991C23.8946 23.8034 23.9999 23.5491 23.9999 23.284C23.9999 23.0188 23.8946 22.7645 23.7072 22.577L13.4142 12.284L23.7072 1.99097C23.8946 1.80344 23.9999 1.54913 23.9999 1.28397C23.9999 1.0188 23.8946 0.764494 23.7072 0.576966Z"
        fill="#100C20"
      />
    </g>
    <defs>
      <clipPath id="clip0_6107_8093">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.28418)"
        />
      </clipPath>
    </defs>
  </svg>
</div>

<style>
  #chipp-close-icon-container {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
  }
</style>
