<script>
  export let backgroundColor;
</script>

<div
  id="chipp-notification-bubble"
  style={`background-color: ${backgroundColor};`}
/>

<style>
  #chipp-notification-bubble {
    position: absolute;
    z-index: 99999999;
    top: 3px;
    right: 3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #676767;
  }
</style>
