<script>
  export let appUrl;
  export let visible;

  import { fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";
</script>

<iframe
  src={`${appUrl}?isChatEmbed=true`}
  title="Chipp Chat Widget"
  rel="preload"
  style="display: none;"
/>

{#if visible}
  <iframe
    src={`${appUrl}?isChatEmbed=true`}
    title="Chipp Chat Widget"
    id="chipp-chat-widget-full"
    transition:fly={{
      delay: 0,
      duration: 300,
      x: 0,
      y: 500,
      opacity: 0,
      easing: quintOut,
    }}
  />
{/if}

<style>
  #chipp-chat-widget-full {
    border: none;
    border-radius: 12px;
    position: fixed;
    z-index: 2147483646;
    bottom: 130px;
    right: 25px;
    width: 390px;
    height: 600px;
    transition:
      opacity 150ms ease-in-out,
      transform 150ms ease-in-out;
    box-shadow: 0px 18px 32px -8px rgba(0, 0, 0, 0.12);
    background-color: white;
  }
  @media (max-width: 768px) {
    #chipp-chat-widget-full {
      left: 16px;
      right: 16px;
      top: 16px;
      bottom: 130px;
      width: calc(100% - 32px);
      height: calc(95% - 130px);
      border: none;
    }
  }
</style>
