<script>
  export let applicationInfo;
  export let visible;
  export let onClick;

  import CreatedOnChipp from "./CreatedOnChipp.svelte";
  import AppLogo from "./AppLogo.svelte";
  import PreviewMessage from "./PreviewMessage.svelte";
  import { spring } from "svelte/motion";
  import { onMount, onDestroy } from "svelte";

  let translateY = spring(300, { stiffness: 0.05, damping: 0.4 });

  function updateAnimations() {
    translateY.set(visible ? 0 : 300);
  }
  $: visible, updateAnimations();

  // The Chipp builder will send a message to this widget to update the preview message
  // as the user types in the Chipp builder.
  // The Chipp builder fires a custom event called "chipp:widget:previewMessageChanged"
  // to make this reactive.
  // e.g. window.dispatchEvent(new CustomEvent('chipp:widget:previewMessageChanged', { detail: 'Hello world!' }));
  let liveUpdatePreviewMessage = applicationInfo.startingMessage || "";
  function handleLiveUpdateChange(event) {
    liveUpdatePreviewMessage = event.detail;
  }
  onMount(() => {
    window.addEventListener(
      "chipp:widget:previewMessageChanged",
      handleLiveUpdateChange
    );
  });

  onDestroy(() => {
    window.removeEventListener(
      "chipp:widget:previewMessageChanged",
      handleLiveUpdateChange
    );
  });
</script>

<div
  id="chipp-chat-widget-preview"
  on:click={onClick}
  style="transform: translateY({$translateY}px); opacity: {visible &&
  (liveUpdatePreviewMessage || applicationInfo.startingMessage)
    ? 1
    : 0};"
>
  <div style="padding: 12px 16px;">
    <div style="display: flex; align-items: center;">
      {#if applicationInfo.brandStyles && applicationInfo.brandStyles.logoUrl}
        <div
          style="border-radius: 50%; overflow: hidden; width: 28px; height: 28px; margin-right: 8px;"
        >
          <AppLogo
            logoUrl={applicationInfo.brandStyles.logoUrl}
            name={applicationInfo.name}
          />
        </div>
      {/if}
      <div style="color: #8C8A94; font-size: 14px; font-weight: 600;">
        {applicationInfo.name}
      </div>
    </div>
    <PreviewMessage
      message={liveUpdatePreviewMessage || applicationInfo.startingMessage}
    />
  </div>
  {#if applicationInfo.isFreeApp || (applicationInfo.developer && applicationInfo.developer.subscriptionTier === "FREE")}
    <CreatedOnChipp />
  {/if}
</div>

<style>
  #chipp-chat-widget-preview {
    cursor: pointer;
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    position: fixed;
    z-index: 2147483646;
    bottom: 125px;
    right: 25px;
    width: 280px;
    background-color: #fff;
    transition: opacity 300ms ease-in-out;
    box-shadow:
      0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);
  }
</style>
