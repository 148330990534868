<div
  style="padding: 4px 10px; border-top: 1px solid #ECECED; color: #8C8A94; font-size: 12px; margin-top: 8px; display: flex; align-items: center; justify-content: center;"
>
  Created on <svg xmlns="http://www.w3.org/2000/svg" width="52" height="17" viewBox="0 0 52 17" fill="none" style="margin-left: 4px;">
    <g clipPath="url(#clip0_6107_6707)">
      <mask id="mask0_6107_6707" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="52" height="17">
        <path d="M52 0.785156H0V16.7852H52V0.785156Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_6107_6707)">
        <path d="M21.7268 14.3419C21.7124 14.3419 21.6979 14.3419 21.6821 14.3419C20.3543 14.3347 19.1002 13.8748 18.1554 13.0492C17.0704 12.1023 16.4968 10.7782 16.4968 9.22134C16.4968 5.59913 19.067 3.97656 21.6141 3.97656C22.9274 3.97656 24.8085 4.17653 25.9758 5.90622C26.5379 6.74463 26.6217 7.66305 26.2056 8.43006C26.0076 8.79427 25.7086 9.09708 25.3502 9.31561C25.5973 9.38702 25.8169 9.53271 25.9802 9.73696C26.2446 10.0698 26.3327 10.5268 26.2214 10.9939C25.7635 13.0306 24.0024 14.3405 21.7268 14.3405V14.3419ZM19.4585 6.54038C18.6855 7.20028 18.375 8.23151 18.375 9.22134C18.375 11.4509 20.0957 12.4765 21.695 12.485C22.0288 12.4893 22.3827 12.4436 22.7194 12.3394C22.6674 12.3422 22.614 12.3436 22.5618 12.3436C20.9553 12.3436 19.7099 11.0339 19.3892 9.00566C19.2202 7.93443 19.2418 7.13171 19.4585 6.53896V6.54038ZM25.2751 11.0324H25.2737C25.2737 11.0324 25.2737 11.0324 25.2751 11.0324ZM21.1879 7.31597C21.1489 7.52736 21.1229 7.94871 21.2443 8.72143C21.2732 8.9014 21.552 10.4883 22.5618 10.4883C22.9809 10.4883 23.4562 10.2326 23.8997 9.76838C23.92 9.74696 23.9402 9.72696 23.9604 9.70696C22.9491 9.70696 22.0866 9.13706 21.6561 8.18295C21.4336 7.68305 21.2832 7.43737 21.1894 7.3174L21.1879 7.31597ZM22.3827 5.87194C22.8827 6.33043 23.1774 6.99317 23.371 7.43023C23.4981 7.71161 23.6902 7.85015 23.9589 7.85015C24.2089 7.85015 24.4574 7.72589 24.5499 7.5545C24.6525 7.36738 24.5311 7.11457 24.4112 6.93746C23.9907 6.31471 23.3652 5.97906 22.3827 5.87336V5.87194Z" fill="white"/>
        <path d="M31.454 14.2908C31.0177 14.2908 30.5814 14.2579 30.0396 14.1837C29.7535 14.1465 29.5166 14.0637 29.3244 13.9508C29.1438 14.0679 28.9328 14.148 28.6988 14.1822C28.17 14.2565 27.7395 14.2908 27.3379 14.2908C26.9016 14.2908 26.4651 14.2579 25.9234 14.1837C24.8673 14.0451 24.4902 13.3181 24.4902 12.6839C24.4902 12.0497 24.8817 11.6469 25.126 11.4484V6.02938C25.1056 6.0051 25.0796 5.97653 25.058 5.95225C24.86 5.73372 24.4902 5.32523 24.4902 4.6682C24.4902 3.91833 25.0696 3.11133 26.3395 3.11133H27.1746C28.2929 3.11133 29.4977 3.93262 29.4977 5.26095V6.23649C29.8214 6.12794 30.1623 6.07224 30.5163 6.07224C32.3974 6.07224 33.6139 7.24059 33.6139 9.04741V11.4556C33.8032 11.6555 34.1123 12.0269 34.1123 12.6482C34.1123 13.4509 33.5908 14.068 32.8135 14.1822C32.2847 14.2565 31.8542 14.2908 31.4526 14.2908H31.454ZM30.8674 12.4082C31.2546 12.4411 31.5696 12.4439 31.9756 12.4097C31.8513 12.2297 31.7371 11.9812 31.7371 11.6598V9.04599C31.7371 8.52465 31.5985 7.92762 30.5178 7.92762C30.4412 7.92762 30.3676 7.93332 30.2952 7.94618C30.707 8.18186 31.1202 8.70462 31.1202 9.86013V11.6598C31.1202 11.9797 31.0046 12.224 30.8659 12.4068L30.8674 12.4082ZM26.8018 12.4125C27.1674 12.4411 27.4708 12.4439 27.8594 12.4111C27.7352 12.2311 27.621 11.9826 27.621 11.6612V10.7857C27.621 10.3343 27.7655 9.44449 28.1542 8.74604C27.8537 8.58321 27.621 8.25898 27.621 7.78907V5.26095C27.621 5.05527 27.3017 4.96814 27.176 4.96814H26.6718C26.8409 5.19524 27.0056 5.50948 27.0056 5.9394V11.7469C27.0056 12.0226 26.9174 12.2411 26.8033 12.4125H26.8018Z" fill="white"/>
        <path d="M35.4142 14.2917C34.9952 14.2917 34.5401 14.2574 33.9824 14.1846C32.9205 14.0446 32.542 13.3176 32.542 12.6848C32.542 12.0521 32.9336 11.6478 33.1777 11.4493V9.11259C33.1575 9.08831 33.1315 9.05974 33.1098 9.03546C32.9119 8.81693 32.542 8.40843 32.542 7.7514C32.542 7.20436 32.7905 6.79729 33.1517 6.53591C32.9292 6.19597 32.8036 5.78604 32.8036 5.33327C32.8036 4.04492 33.8177 3.10938 35.2148 3.10938C36.6119 3.10938 37.6261 4.04492 37.6261 5.33327C37.6261 5.94459 37.3978 6.47592 37.0078 6.86585C37.356 7.23435 37.551 7.73854 37.551 8.3413V11.4465C37.7952 11.645 38.1867 12.0292 38.1867 12.6819C38.1867 13.0619 38.0494 13.9675 36.7781 14.1774C36.2681 14.2545 35.8389 14.2888 35.4142 14.2888V14.2917ZM34.8536 12.412C35.2308 12.442 35.5355 12.4434 35.8765 12.4177C35.7609 12.2463 35.6714 12.0264 35.6714 11.7493V8.34558C35.6714 8.16276 35.6714 8.05278 35.2264 8.05278H34.7221C34.8912 8.27988 35.0559 8.59412 35.0559 9.0226V11.7478C35.0559 12.0235 34.9692 12.2406 34.8536 12.412ZM35.2133 4.96904C34.6803 4.96904 34.6803 5.23471 34.6803 5.33612C34.6803 5.43753 34.6803 5.7032 35.2133 5.7032C35.7465 5.7032 35.7465 5.43753 35.7465 5.33612C35.7465 5.23471 35.7465 4.96904 35.2133 4.96904Z" fill="white"/>
        <path d="M38.964 16.707C38.545 16.707 38.0899 16.6727 37.5322 16.5999C36.4703 16.4599 36.0918 15.7329 36.0918 15.1002C36.0918 14.4673 36.4863 14.0588 36.7275 13.859V9.1112C36.7073 9.08691 36.6813 9.05835 36.6596 9.03406C36.4617 8.81553 36.0918 8.40704 36.0918 7.75001C36.0918 7.00014 36.6712 6.19314 37.9411 6.19314H38.7762C39.1533 6.19314 39.526 6.28312 39.8713 6.45738C40.3741 6.19886 40.9722 6.07031 41.6816 6.07031C43.6017 6.07031 45.5391 7.34865 45.5391 10.2053C45.5391 13.2577 43.528 14.3403 41.8059 14.3403C41.709 14.3403 41.6137 14.3374 41.5197 14.3317C41.6426 14.5317 41.735 14.7845 41.735 15.1016C41.735 15.4815 41.5979 16.3871 40.3264 16.597C39.8164 16.6741 39.3873 16.7084 38.9626 16.7084L38.964 16.707ZM38.3962 14.8259C38.7777 14.8559 39.0825 14.8587 39.4278 14.8316C39.3122 14.6602 39.2226 14.4402 39.2226 14.1632V13.1276C39.2226 12.7634 39.3974 12.4477 39.6676 12.2621C39.3122 11.5036 39.2602 10.5038 39.2313 9.93676L39.224 9.77393L39.2298 9.20403C39.2487 9.03121 39.3021 8.70269 39.4436 8.36419C39.3353 8.32133 39.2342 8.26135 39.1446 8.18708C39.0189 8.09566 38.8989 8.04995 38.7777 8.04995H38.2734C38.4424 8.27706 38.6071 8.59129 38.6071 9.01978V14.1503C38.6071 14.4316 38.5161 14.6531 38.3977 14.8259H38.3962ZM42.3794 8.03282C42.797 8.74126 42.8403 9.73536 42.8591 10.1781C42.8779 10.6238 42.9183 11.6093 42.576 12.3506C43.291 12.0692 43.6638 11.3408 43.6638 10.2053C43.6638 8.77126 42.9892 8.22992 42.3794 8.03282Z" fill="white"/>
        <path d="M46.4237 16.707C46.0047 16.707 45.5496 16.6727 44.9919 16.5999C43.93 16.4599 43.5515 15.7329 43.5515 15.1002C43.5515 14.4673 43.946 14.0588 44.1872 13.859V9.1112C44.1669 9.08691 44.1409 9.05835 44.1193 9.03406C43.9213 8.81553 43.5515 8.40704 43.5515 7.75001C43.5515 7.00014 44.1309 6.19314 45.4008 6.19314H46.2359C46.613 6.19314 46.9842 6.28312 47.3311 6.45738C47.8338 6.19886 48.4319 6.07031 49.1413 6.07031C51.0614 6.07031 52.9988 7.34865 52.9988 10.2053C52.9988 13.2577 50.9878 14.3403 49.2656 14.3403C49.1687 14.3403 49.0734 14.3374 48.9795 14.3317C49.1023 14.5317 49.1947 14.7845 49.1947 15.1016C49.1947 15.4815 49.0575 16.3871 47.7861 16.597C47.2762 16.6741 46.847 16.7084 46.4223 16.7084L46.4237 16.707ZM45.856 14.8259C46.2374 14.8559 46.5421 14.8587 46.8875 14.8316C46.7719 14.6602 46.6823 14.4402 46.6823 14.1632V13.1276C46.6823 12.7634 46.8571 12.4477 47.1273 12.2621C46.7719 11.5036 46.7199 10.5038 46.691 9.93676L46.6837 9.77393L46.6895 9.20403C46.7083 9.03121 46.7617 8.70269 46.9033 8.36419C46.795 8.32133 46.6939 8.26135 46.6043 8.18708C46.4786 8.09566 46.3587 8.04995 46.2374 8.04995H45.7331C45.9022 8.27706 46.0669 8.59129 46.0669 9.01978V14.1503C46.0669 14.4316 45.9758 14.6531 45.8574 14.8259H45.856ZM49.8392 8.03282C50.2566 8.74126 50.3 9.73536 50.3188 10.1781C50.3375 10.6238 50.378 11.6093 50.0357 12.3506C50.7507 12.0692 51.1236 11.3408 51.1236 10.2053C51.1236 8.77126 50.4488 8.22992 49.8392 8.03282Z" fill="white"/>
        <path d="M8.8848 5.87256C8.3589 5.87256 7.74487 5.7026 7.48049 4.93559C7.37501 4.62136 7.41836 4.28142 7.59751 3.99718C7.68853 3.85293 7.81134 3.72723 7.96737 3.61725C7.96014 3.60868 7.95292 3.60012 7.9457 3.59011C7.6683 3.22875 7.58162 2.76598 7.70731 2.32177C7.96592 1.39765 8.72587 0.927734 9.96692 0.927734C10.8425 0.927734 11.5851 1.25482 12.0546 1.84614C12.514 2.42461 12.6571 3.18733 12.4577 3.99433C12.1948 5.05272 11.2282 5.73545 9.99582 5.73545H9.96692C9.8918 5.72973 9.71987 5.76686 9.59273 5.79115C9.42947 5.82258 9.16941 5.87256 8.8848 5.87256Z" fill="white"/>
        <path d="M8.87672 6.02279C8.12977 6.02279 7.57065 5.64286 7.34382 4.98155C7.22391 4.62733 7.27303 4.24168 7.4753 3.92174C7.55331 3.7989 7.65012 3.68892 7.76859 3.59037C7.51431 3.20758 7.44063 2.73767 7.56921 2.28346C7.91451 1.04511 9.06454 0.785156 9.96752 0.785156C10.8705 0.785156 11.6709 1.13081 12.1707 1.75927C12.6577 2.37344 12.8108 3.18044 12.5999 4.03029C12.3196 5.15437 11.2981 5.87996 9.99642 5.87996H9.97475C9.9025 5.87711 9.72191 5.91424 9.62222 5.93281C9.41128 5.9728 9.15122 6.02279 8.87672 6.02279Z" fill="white"/>
        <path d="M2.41837 16.642C2.00806 16.642 1.60642 16.5049 1.27267 16.242C0.758332 15.8364 0.505499 15.2094 0.595074 14.5652L0.782893 13.2198C0.365356 12.4328 0.145752 11.5629 0.145752 10.6802C0.145752 7.64789 2.64086 5.18262 5.70665 5.18262H9.64797C12.7152 5.18262 15.2089 7.64933 15.2089 10.6802C15.2089 13.7112 12.7137 16.1778 9.64797 16.1778H5.70665C5.2689 16.1778 4.83402 16.1264 4.40781 16.0249L3.07574 16.522C2.86046 16.602 2.63797 16.642 2.41837 16.642ZM5.70665 7.03943C3.67531 7.03943 2.02395 8.67342 2.02395 10.6802C2.02395 11.3358 2.20888 11.9828 2.55995 12.5542L2.73333 12.8356L2.46316 14.7652L4.32979 14.0682L4.63608 14.1611C4.98427 14.2667 5.34547 14.3209 5.70665 14.3209H9.64797C11.6793 14.3209 13.3307 12.687 13.3307 10.6802C13.3307 8.67342 11.6779 7.03943 9.64797 7.03943H5.70665Z" fill="white"/>
        <path d="M2.42154 16.7855C1.97512 16.7855 1.53446 16.6327 1.18194 16.3541C0.62715 15.9171 0.35409 15.2401 0.450888 14.5459L0.632929 13.2462C0.218281 12.4506 0.00012207 11.5693 0.00012207 10.6809C0.00012207 7.57004 2.56025 5.03906 5.70694 5.03906H9.64826C12.795 5.03906 15.3551 7.57004 15.3551 10.6809C15.3551 13.7917 12.795 16.3227 9.64826 16.3227H5.70694C5.27351 16.3227 4.84009 16.2728 4.41677 16.1756L3.12659 16.6569C2.89832 16.7412 2.66139 16.7855 2.42011 16.7855H2.42154ZM5.70839 7.18297C3.75796 7.18297 2.17015 8.75125 2.17015 10.6809C2.17015 11.3094 2.34786 11.9321 2.6845 12.4806L2.88387 12.8062L2.64115 14.5473L4.32431 13.9189L4.67827 14.026C5.01345 14.1274 5.3602 14.1788 5.70694 14.1788H9.64826C11.5987 14.1788 13.1865 12.6091 13.1865 10.6809C13.1865 8.75269 11.5987 7.18297 9.64826 7.18297H5.70694H5.70839Z" fill="white"/>
        <path d="M3.44423 15.1274C3.03391 15.1274 2.63227 14.9903 2.29708 14.7275C1.78274 14.3217 1.52992 13.6948 1.61949 13.0506L1.8073 11.7051C1.38977 10.9181 1.17017 10.0483 1.17017 9.16557C1.17017 6.13325 3.66528 3.66797 6.73106 3.66797H10.6724C13.7396 3.66797 16.2333 6.13468 16.2333 9.16557C16.2333 12.1964 13.7382 14.6631 10.6724 14.6631H6.73106C6.29331 14.6631 5.85843 14.6118 5.43222 14.5103L4.10015 15.0074C3.88487 15.0874 3.66239 15.1274 3.44278 15.1274H3.44423ZM6.73251 5.52478C4.70118 5.52478 3.0498 7.15877 3.0498 9.16557C3.0498 9.81973 3.23473 10.4682 3.58581 11.0395L3.75918 11.3209L3.48901 13.2506L5.35565 12.5535L5.66194 12.6464C6.01013 12.7521 6.36988 12.805 6.73251 12.805H10.6738C12.7052 12.805 14.3565 11.1709 14.3565 9.16413C14.3565 7.15735 12.7037 5.52336 10.6738 5.52336H6.73251V5.52478Z" fill="white"/>
        <path d="M3.44682 15.2694C3.00038 15.2694 2.55973 15.1165 2.2072 14.8381C1.65242 14.3995 1.37936 13.7239 1.47616 13.0297L1.6582 11.7286C1.24355 10.933 1.02539 10.0532 1.02539 9.16333C1.02539 6.05247 3.58552 3.52148 6.73222 3.52148H10.6735C13.8202 3.52148 16.3803 6.05247 16.3803 9.16333C16.3803 12.2742 13.8202 14.8051 10.6735 14.8051H6.73222C6.29878 14.8051 5.86535 14.7552 5.44349 14.658L4.15331 15.1395C3.92648 15.2236 3.68809 15.2679 3.44682 15.2679V15.2694ZM6.73222 5.66682C4.78178 5.66682 3.19398 7.23654 3.19398 9.16477C3.19398 9.79322 3.37169 10.416 3.70832 10.9644L3.9077 11.2901L3.66498 13.0312L5.34812 12.4028L5.7021 12.5099C6.03728 12.6113 6.38258 12.6627 6.73077 12.6627H10.6721C12.6225 12.6627 14.2104 11.0944 14.2104 9.16477C14.2104 7.23511 12.6225 5.66682 10.6721 5.66682H6.73077H6.73222Z" fill="white"/>
        <path d="M9.59508 11.0239H8.65598C7.40482 11.0239 6.3877 10.0183 6.3877 8.78142V7.25026C6.3877 6.01334 7.40482 5.00781 8.65598 5.00781H9.59508V11.0239Z" fill="white"/>
        <path d="M8.65565 11.0239H7.71655V5.00781H8.65565C9.90682 5.00781 10.9239 6.01334 10.9239 7.25026V8.78142C10.9239 10.0183 9.90682 11.0239 8.65565 11.0239Z" fill="white"/>
        <path d="M8.65569 9.4734C7.84373 9.4734 7.18347 8.82066 7.18347 8.01795C7.18347 7.21524 7.84373 6.5625 8.65569 6.5625C9.46764 6.5625 10.1279 7.21524 10.1279 8.01795C10.1279 8.82066 9.46764 9.4734 8.65569 9.4734ZM8.65569 7.61659C8.43175 7.61659 8.2497 7.79657 8.2497 8.01795C8.2497 8.23934 8.43175 8.41931 8.65569 8.41931C8.87963 8.41931 9.06166 8.23934 9.06166 8.01795C9.06166 7.79657 8.87963 7.61659 8.65569 7.61659Z" fill="white"/>
        <path d="M13.2833 11.0239H12.3442C11.093 11.0239 10.0759 10.0183 10.0759 8.78142V7.25026C10.0759 6.01334 11.093 5.00781 12.3442 5.00781H13.2833V11.0239Z" fill="white"/>
        <path d="M12.3436 11.0239H11.4045V5.00781H12.3436C13.5948 5.00781 14.6119 6.01334 14.6119 7.25026V8.78142C14.6119 10.0183 13.5948 11.0239 12.3436 11.0239Z" fill="white"/>
        <path d="M12.3445 9.4734C11.5326 9.4734 10.8723 8.82066 10.8723 8.01795C10.8723 7.21524 11.5326 6.5625 12.3445 6.5625C13.1565 6.5625 13.8167 7.21524 13.8167 8.01795C13.8167 8.82066 13.1565 9.4734 12.3445 9.4734ZM12.3445 7.61659C12.1206 7.61659 11.9385 7.79657 11.9385 8.01795C11.9385 8.23934 12.1206 8.41931 12.3445 8.41931C12.5685 8.41931 12.7505 8.23934 12.7505 8.01795C12.7505 7.79657 12.5685 7.61659 12.3445 7.61659Z" fill="white"/>
        <path d="M10.3533 13.0634C8.91574 13.0634 7.90151 12.1179 7.79026 12.0093L7.12134 11.3594L8.93741 9.52832L9.60778 10.1611C9.70314 10.2482 10.0354 10.4853 10.3547 10.4853H10.3692C10.5801 10.481 10.8127 10.3525 11.0554 10.1025L11.7056 9.43262L13.5881 11.2152L12.9379 11.885C12.204 12.642 11.327 13.0491 10.4024 13.0634H10.3547H10.3533Z" fill="white"/>
        <path d="M6.32966 11.6462C5.64484 11.6462 5.08716 11.0948 5.08716 10.4178C5.08716 9.74077 5.64484 9.18945 6.32966 9.18945C7.01447 9.18945 7.57215 9.74077 7.57215 10.4178C7.57215 11.0948 7.01447 11.6462 6.32966 11.6462ZM6.32966 9.78935C5.98002 9.78935 5.69396 10.0707 5.69396 10.4178C5.69396 10.7649 5.97857 11.0463 6.32966 11.0463C6.68073 11.0463 6.96535 10.7649 6.96535 10.4178C6.96535 10.0707 6.68073 9.78935 6.32966 9.78935Z" fill="white"/>
        <path d="M6.32966 12.6354C5.64484 12.6354 5.08716 12.0841 5.08716 11.4071C5.08716 10.73 5.64484 10.1787 6.32966 10.1787C7.01447 10.1787 7.57215 10.73 7.57215 11.4071C7.57215 12.0841 7.01447 12.6354 6.32966 12.6354ZM6.32966 10.7786C5.98002 10.7786 5.69396 11.06 5.69396 11.4071C5.69396 11.7541 5.97857 12.0355 6.32966 12.0355C6.68073 12.0355 6.96535 11.7541 6.96535 11.4071C6.96535 11.06 6.68073 10.7786 6.32966 10.7786Z" fill="white"/>
        <path d="M7.20832 12.1286C6.5235 12.1286 5.96582 11.5772 5.96582 10.9002C5.96582 10.2232 6.5235 9.67188 7.20832 9.67188C7.89313 9.67188 8.45082 10.2232 8.45082 10.9002C8.45082 11.5772 7.89313 12.1286 7.20832 12.1286ZM7.20832 10.2718C6.85868 10.2718 6.57262 10.5531 6.57262 10.9002C6.57262 11.2473 6.85723 11.5287 7.20832 11.5287C7.55939 11.5287 7.84401 11.2473 7.84401 10.9002C7.84401 10.5531 7.55939 10.2718 7.20832 10.2718Z" fill="white"/>
        <path d="M21.6136 4.90279C23.0483 4.90279 24.3702 5.19845 25.1937 6.41966C26.0794 7.73942 25.0435 8.77496 23.9584 8.77496C23.3964 8.77496 22.8229 8.49072 22.5122 7.80084C22.1004 6.87672 21.7263 6.28397 21.1022 6.28397H21.0155C20.2425 6.35824 20.0792 7.35664 20.3177 8.86065C20.504 10.0447 21.1903 11.4131 22.5628 11.4131C23.2361 11.4131 23.9469 11.0674 24.584 10.4018C24.7212 10.2533 24.8716 10.1919 24.9958 10.1919C25.2328 10.1919 25.3946 10.4261 25.3079 10.7832C24.9092 12.5714 23.3372 13.4213 21.6901 13.4098C19.5953 13.397 17.4368 11.9915 17.4368 9.21774C17.4368 6.28255 19.4204 4.90137 21.615 4.90137L21.6136 4.90279Z" fill="black"/>
        <path d="M25.4309 4.6685C25.4309 4.32285 25.7054 4.04004 26.3411 4.04004H27.1762C27.7873 4.04004 28.5603 4.45997 28.5603 5.26126V7.78938C28.5603 7.88792 28.5849 7.93791 28.6225 7.93791C28.647 7.93791 28.6976 7.91364 28.7467 7.85222C29.1961 7.33373 29.7942 7.00095 30.5179 7.00095C31.8529 7.00095 32.6764 7.72795 32.6764 9.04772V11.6615C32.6764 12.0315 33.1749 12.0929 33.1749 12.6485C33.1749 13.0556 32.9249 13.2284 32.6764 13.2655C32.2401 13.327 31.84 13.3641 31.4542 13.3641C31.0294 13.3641 30.619 13.327 30.1698 13.2655C29.7956 13.217 29.5457 13.0556 29.5457 12.6485C29.5457 12.0943 30.1813 12.0315 30.1813 11.6615V9.86185C30.1813 9.10914 29.9949 8.69064 29.5832 8.69064C28.9345 8.69064 28.5603 10.2204 28.5603 10.7874V11.663C28.5603 12.0329 29.0588 12.0943 29.0588 12.6499C29.0588 13.057 28.8088 13.2298 28.5603 13.2669C28.124 13.3284 27.7237 13.3656 27.3381 13.3656C26.9133 13.3656 26.5029 13.3284 26.0537 13.2669C25.6794 13.2183 25.4294 13.0698 25.4294 12.6871C25.4294 12.1572 26.0652 12.0957 26.0652 11.7501V5.94256C26.0652 5.3998 25.4294 5.26411 25.4294 4.67278L25.4309 4.6685Z" fill="black"/>
        <path d="M36.6119 11.7472C36.6119 12.0929 37.2477 12.1543 37.2477 12.6842C37.2477 13.0671 36.9977 13.2027 36.6236 13.2641C36.2118 13.3256 35.8246 13.3627 35.4143 13.3627C35.0039 13.3627 34.5778 13.3256 34.1039 13.2641C33.7297 13.2156 33.4797 13.0671 33.4797 12.6842C33.4797 12.1543 34.1153 12.0929 34.1153 11.7472V9.02201C34.1153 8.47925 33.4797 8.34356 33.4797 7.75223C33.4797 7.40658 33.7297 7.12378 34.2657 7.12378H35.2265C36.0875 7.12378 36.6105 7.5437 36.6105 8.34498V11.7487L36.6119 11.7472ZM35.2148 4.04004C36.1005 4.04004 36.687 4.55852 36.687 5.33553C36.687 6.11253 36.1005 6.63101 35.2148 6.63101C34.3293 6.63101 33.7426 6.11253 33.7426 5.33553C33.7426 4.55852 34.3293 4.04004 35.2148 4.04004Z" fill="black"/>
        <path d="M37.6666 14.1516V9.02106C37.6666 8.4783 37.031 8.34261 37.031 7.7513C37.031 7.40564 37.3055 7.12283 37.9411 7.12283H38.7763C39.1375 7.12283 39.4625 7.25852 39.737 7.46849C39.7745 7.50562 39.8236 7.51847 39.8612 7.51847C39.9103 7.51847 39.9609 7.50562 40.0116 7.46849C40.4363 7.14854 40.9838 7 41.6831 7C43.1929 7 44.6016 7.96125 44.6016 10.2066C44.6016 12.4519 43.3172 13.4131 41.8074 13.4131C41.2584 13.4131 40.7599 13.2903 40.3236 13.0432C40.2861 13.0189 40.2614 13.0189 40.2369 13.0189C40.1863 13.0189 40.1618 13.0561 40.1618 13.1303V14.1658C40.1618 14.5115 40.7975 14.573 40.7975 15.1029C40.7975 15.4856 40.5475 15.6213 40.1734 15.6827C39.7616 15.7442 39.3744 15.7813 38.9641 15.7813C38.5537 15.7813 38.1276 15.7442 37.6537 15.6827C37.2795 15.6328 37.0295 15.4856 37.0295 15.1029C37.0295 14.573 37.6651 14.4987 37.6651 14.153L37.6666 14.1516ZM40.1618 9.77379C40.1994 10.4394 40.2485 12.3505 41.134 12.3505H41.1716C41.7337 12.3262 41.9821 11.722 41.9201 10.2166C41.8579 8.76111 41.5458 8.16979 41.0214 8.16979H40.9838C40.5721 8.19407 40.2485 8.51544 40.1603 9.30387V9.77236L40.1618 9.77379Z" fill="black"/>
        <path d="M45.1263 14.1516V9.02106C45.1263 8.4783 44.4907 8.34261 44.4907 7.7513C44.4907 7.40564 44.7652 7.12283 45.4008 7.12283H46.236C46.5971 7.12283 46.9223 7.25852 47.1966 7.46849C47.2343 7.50562 47.2834 7.51847 47.321 7.51847C47.3701 7.51847 47.4206 7.50562 47.4712 7.46849C47.8945 7.14854 48.4436 7 49.1428 7C50.6526 7 52.0612 7.96125 52.0612 10.2066C52.0612 12.4519 50.7768 13.4131 49.2671 13.4131C48.7181 13.4131 48.2196 13.2903 47.7833 13.0432C47.7457 13.0189 47.7211 13.0189 47.6966 13.0189C47.646 13.0189 47.6215 13.0561 47.6215 13.1303V14.1658C47.6215 14.5115 48.2572 14.573 48.2572 15.1029C48.2572 15.4856 48.0072 15.6213 47.633 15.6827C47.2213 15.7442 46.8341 15.7813 46.4238 15.7813C46.0135 15.7813 45.5872 15.7442 45.1134 15.6827C44.7391 15.6328 44.4893 15.4856 44.4893 15.1029C44.4893 14.573 45.1249 14.4987 45.1249 14.153L45.1263 14.1516ZM47.6215 9.77379C47.6591 10.4394 47.7082 12.3505 48.5938 12.3505H48.6314C49.1933 12.3262 49.4418 11.722 49.3798 10.2166C49.3176 8.76111 49.0055 8.16979 48.481 8.16979H48.4436C48.0318 8.19407 47.7082 8.51544 47.62 9.30387V9.77236L47.6215 9.77379Z" fill="black"/>
        <path d="M10.0989 2.37921C10.0816 2.37921 10.07 2.3735 10.0584 2.3735C9.78249 2.3735 9.95008 2.99196 9.51954 3.19049C9.41119 3.24192 9.30138 3.26477 9.19736 3.26477C8.81884 3.26477 8.50242 2.95768 8.61223 2.57203C8.77259 1.99928 9.28404 1.85645 9.96742 1.85645C11.1377 1.85645 11.8211 2.66201 11.5466 3.77467C11.3688 4.49026 10.6912 4.80735 9.99631 4.80735C9.42274 4.7902 8.57756 5.23727 8.37095 4.63595C8.30739 4.44884 8.62234 4.16889 9.70447 4.02891C10.1422 3.97178 10.4283 3.71611 10.4904 3.29618C10.5713 2.80771 10.3532 2.42206 10.1003 2.37635L10.0989 2.37921Z" fill="black"/>
        <path d="M8.87675 5.09417C8.59502 5.09417 8.34363 5.00561 8.23238 4.68281C8.20204 4.59568 8.21649 4.49998 8.2714 4.41429C8.42743 4.16861 8.91576 3.98722 9.68437 3.88724C10.0643 3.83868 10.2926 3.62729 10.3461 3.27592C10.414 2.86171 10.2363 2.55747 10.0817 2.52034C10.073 2.52034 10.0643 2.51891 10.0586 2.51749C10.0412 2.53891 10.0167 2.63461 10.0008 2.69888C9.94877 2.90313 9.87797 3.18307 9.5818 3.3202C9.22349 3.48731 8.7944 3.39447 8.58057 3.11595C8.45199 2.94884 8.41442 2.74173 8.47366 2.53177C8.67737 1.80475 9.37953 1.71191 9.96899 1.71191C10.596 1.71191 11.1161 1.93187 11.4325 2.33037C11.7374 2.71602 11.8284 3.24021 11.6883 3.80725C11.4788 4.64996 10.6769 4.9499 9.99644 4.9499C9.83174 4.94562 9.63381 4.98276 9.4431 5.0199C9.2625 5.05418 9.06313 5.09273 8.87675 5.09273V5.09417ZM10.0586 2.22896C10.0802 2.22896 10.0961 2.23325 10.1062 2.23468H10.1236C10.4443 2.29467 10.7289 2.72459 10.6321 3.3202C10.5613 3.79583 10.2291 4.10577 9.72049 4.17147C8.73082 4.2986 8.54301 4.52284 8.51555 4.56569C8.50688 4.57855 8.50545 4.58711 8.50545 4.58998C8.59791 4.85707 8.85652 4.8385 9.38386 4.73709C9.59047 4.69709 9.80284 4.6571 9.99644 4.66281C10.5613 4.66281 11.2317 4.42001 11.4008 3.74013C11.5192 3.26021 11.4484 2.82315 11.1985 2.50891C10.9384 2.1804 10.4992 2.00044 9.96321 2.00044C9.22205 2.00044 8.86953 2.17755 8.74816 2.61032C8.70482 2.76601 8.75538 2.87741 8.80596 2.94312C8.93887 3.11595 9.21338 3.17165 9.45176 3.06024C9.62225 2.98169 9.66704 2.80315 9.71182 2.62889C9.75516 2.46035 9.81295 2.22896 10.0542 2.22896H10.0586Z" fill="black"/>
        <path d="M9.64772 6.1108H5.7064C3.16361 6.1108 1.08459 8.16757 1.08459 10.68C1.08459 11.5413 1.33309 12.3454 1.75641 13.0352L1.52525 14.6907C1.43134 15.3649 2.10026 15.8934 2.74463 15.6519L4.36132 15.0478C4.78753 15.1778 5.23974 15.2478 5.70784 15.2478H9.64916C12.192 15.2478 14.271 13.1909 14.271 10.6786C14.271 8.16615 12.1905 6.10938 9.64916 6.10938L9.64772 6.1108Z" fill="black"/>
        <path d="M2.4212 15.8565C2.18714 15.8565 1.95887 15.7779 1.76816 15.628C1.47487 15.3966 1.33039 15.0394 1.38241 14.6724L1.60634 13.067C1.17003 12.3385 0.940308 11.5158 0.940308 10.6802C0.940308 8.08071 3.07856 5.9668 5.70803 5.9668H9.64936C12.2788 5.9668 14.4171 8.08071 14.4171 10.6802C14.4171 13.2798 12.2788 15.3937 9.64936 15.3937H5.70803C5.25437 15.3937 4.80361 15.3295 4.36729 15.2023L2.79683 15.7893C2.67403 15.835 2.54689 15.8579 2.42264 15.8579L2.4212 15.8565ZM5.70659 6.25389C3.23749 6.25389 1.22926 8.23925 1.22926 10.6802C1.22926 11.4787 1.45465 12.2671 1.88084 12.9612L1.9083 13.0055L1.66992 14.7124C1.63234 14.9781 1.73782 15.2366 1.94876 15.4037C2.16114 15.5708 2.43853 15.6151 2.69281 15.5194L4.35573 14.8981L4.40341 14.9124C4.82673 15.0409 5.26594 15.1065 5.70659 15.1065H9.64792C12.117 15.1065 14.1253 13.1212 14.1253 10.6802C14.1253 8.23925 12.117 6.25389 9.64792 6.25389H5.70659Z" fill="black"/>
        <path d="M10.674 4.59517H6.73265C4.18986 4.59517 2.11084 6.65195 2.11084 9.16437C2.11084 10.0256 2.35934 10.8298 2.78266 11.5197L2.5515 13.1751C2.45758 13.8493 3.12651 14.3777 3.77089 14.1363L5.38758 13.5321C5.81377 13.6622 6.26599 13.7321 6.7341 13.7321H10.6754C13.2182 13.7321 15.2972 11.6753 15.2972 9.16293C15.2972 6.65053 13.2167 4.59375 10.6754 4.59375L10.674 4.59517Z" fill="#F9DB00"/>
        <path d="M3.4472 14.3409C3.21315 14.3409 2.98487 14.2623 2.79416 14.1123C2.50087 13.8809 2.35639 13.5238 2.40841 13.1567L2.63234 11.5513C2.19603 10.8229 1.96631 9.99875 1.96631 9.16462C1.96631 6.56508 4.10456 4.45117 6.73403 4.45117H10.6754C13.3048 4.45117 15.4431 6.56508 15.4431 9.16462C15.4431 11.7642 13.3048 13.8781 10.6754 13.8781H6.73403C6.28037 13.8781 5.82962 13.8138 5.39329 13.6867L3.82283 14.2736C3.70002 14.3194 3.57289 14.3423 3.44863 14.3423L3.4472 14.3409ZM6.73259 4.73969C4.26349 4.73969 2.25526 6.72505 2.25526 9.16604C2.25526 9.96447 2.48065 10.7529 2.90686 11.4471L2.93431 11.4913L2.69592 13.1982C2.65836 13.4639 2.76382 13.7224 2.97476 13.8895C3.18714 14.0566 3.46454 14.0994 3.71881 14.0052L5.38174 13.3839L5.42941 13.3982C5.85273 13.5267 6.29193 13.5924 6.73259 13.5924H10.6739C13.143 13.5924 15.1512 11.607 15.1512 9.16604C15.1512 6.72505 13.143 4.73969 10.6739 4.73969H6.73259Z" fill="black"/>
        <path d="M8.65537 5.9375C7.92142 5.9375 7.32617 6.52597 7.32617 7.25154V8.78271C7.32617 9.50828 7.92142 10.0967 8.65537 10.0967V5.9375Z" fill="black"/>
        <path d="M8.6554 5.9375V10.0967C9.38933 10.0967 9.98458 9.50828 9.98458 8.78271V7.25154C9.98458 6.52597 9.38933 5.9375 8.6554 5.9375Z" fill="white"/>
        <path d="M8.65544 8.54336C8.94986 8.54336 9.18856 8.30738 9.18856 8.0163C9.18856 7.72522 8.94986 7.48926 8.65544 7.48926C8.36099 7.48926 8.12231 7.72522 8.12231 8.0163C8.12231 8.30738 8.36099 8.54336 8.65544 8.54336Z" fill="white"/>
        <path d="M12.344 5.9375C11.61 5.9375 11.0148 6.52597 11.0148 7.25154V8.78271C11.0148 9.50828 11.61 10.0967 12.344 10.0967V5.9375Z" fill="black"/>
        <path d="M12.3442 5.9375V10.0967C13.0782 10.0967 13.6734 9.50828 13.6734 8.78271V7.25154C13.6734 6.52597 13.0782 5.9375 12.3442 5.9375Z" fill="white"/>
        <path d="M12.3439 8.54336C12.6384 8.54336 12.877 8.30738 12.877 8.0163C12.877 7.72522 12.6384 7.48926 12.3439 7.48926C12.0495 7.48926 11.8108 7.72522 11.8108 8.0163C11.8108 8.30738 12.0495 8.54336 12.3439 8.54336Z" fill="white"/>
        <path d="M10.3533 12.1349C9.26973 12.1349 8.48233 11.3793 8.4491 11.3479L8.9591 10.8337C8.9591 10.8337 9.57457 11.415 10.3533 11.415C10.3634 11.415 10.3721 11.415 10.3822 11.415C10.8561 11.4064 11.3097 11.1808 11.7331 10.7451L12.2589 11.2436C11.6955 11.8249 11.0656 12.1249 10.388 12.1349C10.3764 12.1349 10.3649 12.1349 10.3547 12.1349H10.3533Z" fill="black"/>
        <path d="M6.32904 10.7171C6.4966 10.7171 6.63244 10.5828 6.63244 10.4171C6.63244 10.2515 6.4966 10.1172 6.32904 10.1172C6.16148 10.1172 6.02563 10.2515 6.02563 10.4171C6.02563 10.5828 6.16148 10.7171 6.32904 10.7171Z" fill="white"/>
        <path d="M6.32904 11.7073C6.4966 11.7073 6.63244 11.573 6.63244 11.4074C6.63244 11.2417 6.4966 11.1074 6.32904 11.1074C6.16148 11.1074 6.02563 11.2417 6.02563 11.4074C6.02563 11.573 6.16148 11.7073 6.32904 11.7073Z" fill="white"/>
        <path d="M7.2077 11.1985C7.37526 11.1985 7.5111 11.0642 7.5111 10.8986C7.5111 10.7329 7.37526 10.5986 7.2077 10.5986C7.04014 10.5986 6.9043 10.7329 6.9043 10.8986C6.9043 11.0642 7.04014 11.1985 7.2077 11.1985Z" fill="white"/>
        <path d="M23.3978 9.58382L22.0701 9.15818L21.4806 8.544L21.001 7.48991L20.3003 7L19.7845 7.12283V8.70112L20.2642 9.83949L21.001 10.5994H22.1076L22.7708 10.2623L23.3978 9.58382Z" fill="white"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6107_6707">
        <rect width="52" height="16" fill="white" transform="translate(0 0.785156)"/>
      </clipPath>
    </defs>
  </svg>
</div>
