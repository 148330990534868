<script>
  export let message;
</script>

<div id="chipp-chat-widget-preview-message">
  {message}
</div>

<style>
  #chipp-chat-widget-preview-message {
    text-align: left;
    margin-top: 8px;
    color: #100c20;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
</style>
